import gql from "graphql-tag";

export const GET_DAILY_BETS = gql`
  query MyQuery($betsWhere: bets_bool_exp) {
    bets_aggregate(where: $betsWhere) {
      aggregate {
        sum {
          amount
        }
      }
    }
    bets_count: bets_aggregate(where: $betsWhere) {
      aggregate {
        count
      }
    }
    active_agents: users_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_WEEKLY_BETS = gql`
  query MyQuery($betsWhere: bets_bool_exp) {
    bets_aggregate(where: $betsWhere) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const GET_MONTHLY_BETS = gql`
  query MyQuery($date: timestamptz) {
    bets_aggregate(where: { created_at: { _gte: $date } }) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const GET_AGENT_BETS = gql`
  query MyQuery(
    $resultsWhere: results_bool_exp
    $betsWhere: bets_bool_exp
    $usersWhere: users_bool_exp
  ) {
    serverConfigs(
      where: {
        type: { _in: ["winning_multiplier", "winning_multiplier_same_digit","outlet_winning_multiplier","outlet_winning_multiplier_same_digit"] }
      }
    ) {
      type
      value
    }
    results(where: $resultsWhere) {
      combination
      created_at
      date
      drawNumber
      id
      updated_at
    }
    users(where: $usersWhere) {
      username
      agent_type
      coordinatorId
      coordinator: coordinator_user {  
        username 
      }
      bets_count: bets_aggregate(where: $betsWhere) {
        aggregate {
          count
        }
      }
      bets: bets(where: $betsWhere) {
        agentId
        amount
        created_at
        drawNumber
        id
        number
        type
        updated_at
      }
    }
  }
`;

export const REPORTS_BETS = gql`
  query MyQuery($betsWhere: bets_bool_exp, $order_by: [bets_order_by!]) {
    bets: bets(where: $betsWhere, order_by: $order_by) {
      agentId
      amount
      created_at
      drawNumber
      id
      number
      type
      updated_at
      user {
        username
        agent_type
      }
    }
  }
`;

export const OPSTAT_BETS = gql`
  query MyQuery($betsWhere: bets_bool_exp) {
    bets(where: $betsWhere) {
      amount
      drawNumber
      type
      number
    }
  }
`;

export const MULTIPLIER =gql`
query MyQuery {
  serverConfigs(
      where: {
        type: { _in: ["winning_multiplier", "winning_multiplier_same_digit","outlet_winning_multiplier","outlet_winning_multiplier_same_digit"] }
      }
    ) {
      type
      value
  }
}
`;