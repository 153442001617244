import React, { useState } from "react";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import { useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import { USERS } from "../../lib/subscriptions";

const UsersInfoController = () => {
  const [visible, setVisible] = useState(false);
  const [showPassForm, setShowPassForm] = useState(false);

  const { data: usersInfo, loading: loading_usersInfo } = useSubscription(USERS);


  const pageValues = {
    usersInfo,
    loading_usersInfo,
    visible,
    setVisible,
    showPassForm,
    setShowPassForm,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default UsersInfoController;
