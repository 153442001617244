import React, { useState } from "react";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import { useMutation, useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import { AGENTS, GET_ACTIVE_AGENTS, GET_AGENT_TODAY,GET_COORDINATOR,COORDINATOR } from "../../lib/subscriptions";
import { UPDATE_USER_STATUS } from "../../lib/mutations";

const CoordinatorController = () => { 
  const [visible, setVisible] = useState({
    agent: false,
    bulkagent: false,
  });
  const [Bulkvalue, setBulkValue] = useState(0);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [row, setRow] = useState({});
  const [id, setId] = useState();
  const [showForm, setShowForm] = useState(false);

  const { data: agentsList, loading: loading_agentsList } = useSubscription(AGENTS);
  const { data: agents, loading: loading_agents } = useSubscription(COORDINATOR);
  const { data: coordinator } = useSubscription(GET_COORDINATOR,{
    variables: {
      type: "coordinator"
    }
  });

  const [updateStatus] = useMutation(UPDATE_USER_STATUS);

  const onRowClick = (item) => {
    setRow(item);
    setShowForm(true);
  };

  const showPassModal = () => {
    setResetPassModal(true);
    setVisible({ ...visible, agent: false });
  };

  const closePassModal = () => {
    setResetPassModal(false);
    setVisible({ ...visible, agent: true });
  };

  const handleSetStatus = async (item) => {
    await updateStatus({
      variables: {
        id: item.id,
        status: !item.isActivated,
      },
    });
  }; 

  const pageValues = { 
    agentsList,
    agents,
    loading_agents,
    visible,
    row,
    resetPassModal,
    id,
    showForm,
    setVisible,
    onRowClick,
    setRow,
    setBulkValue,
    Bulkvalue,
    closePassModal,
    showPassModal,
    setId,
    setShowForm,
    handleSetStatus,
    coordinator,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default CoordinatorController;
