import React, { useState } from "react";
import { Button, Layout, Menu } from "antd";
import {
	LogoutOutlined,
	UsergroupAddOutlined,
	FileOutlined,
	SettingOutlined,
	UnorderedListOutlined,
	DashboardOutlined,
	LaptopOutlined,
	OrderedListOutlined,
	UserAddOutlined,
	RightOutlined
} from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

const GuestLayout = ({ children }) => {
	const { Content, Footer, Sider } = Layout;
	const location = useLocation();
	const history = useHistory();
	const [css, setCss] = useState("hidden font-bold text-xl text-red-700");
	const [css2, setCss2] = useState("hidden");
	const [css3, setCss3] = useState(" flex items-center justify-center space-x-3");

	const handleLogout = () => {
		localStorage.removeItem(process.env.REACT_APP_LS_TOKEN);
		history.push("/");
	};

	const hideText = (data) => {
		if (data === false) {
			setTimeout(() => {
				setCss("font-bold text-xl text-red-700");
				setCss2("flex text-md")
				setCss3("flex items-center space-x-3")
			}, [200])
		} else {
			setCss("hidden font-bold text-xl text-red-700");
			setCss2("hidden")
			setCss3("flex items-center justify-center space-x-3")
		}
	}

	const Trigger = () => {
		return (
			<div>
				<RightOutlined />
			</div>
		)
	}

	const getSelectedKey = () => {
		switch (location.pathname) {
			case "/dashboard":
				return "1";
			case "/topup-dashboard":
				return "2";
			case "/agents":
				return "3";
			case "/coordinator":
				return "4";
			case "/limits":
				return "4";
			case "/results":
				return "6";
			case "/bets":
				return "7";
			case "/all-transactions":
				return "8";
			case "/operation-stats":
				return "9";
			case "/server-configuration":
				return "10";
			case "/user-info":
				return "11";
			default:
				break;
		}
	};

	return (
		<div className="flex flex-col h-auto">
			<Layout style={{ minHeight: "100vh" }}>
				<Sider defaultCollapsed={true} theme="light" collapsible width="250" onCollapse={(data) => hideText(data)} trigger={<Trigger />}>
					<div className="flex items-center justify-center space-x-3" style={{ height: 50, margin: 16 }}>
						<img src="./agila.png" width={45} height={45} className="object-contain"></img>
						<p className={css}>Agila</p>
					</div>
					<Menu theme="light" selectedKeys={getSelectedKey()} mode="inline">
						<Menu.Item
							key="1"
							icon={<LaptopOutlined />}
							onClick={() => history.push("/dashboard")}
							style={{ fontSize: 16 }}
						>
							Dashboard
						</Menu.Item>
						{/* <Menu.Item
							key="2"
							icon={<LaptopOutlined />}
							onClick={() => history.push("/topup-dashboard")}
							style={{ fontSize: 16 }}
						>
							TopUp Dashboard
						</Menu.Item> */}
						<Menu.Item
							key="3"
							icon={<UsergroupAddOutlined />}
							onClick={() => history.push("/agents")}
							style={{ fontSize: 16 }}
						>
							Agents
						</Menu.Item>
						<Menu.Item
							key="4"
							icon={<UserAddOutlined />}
							onClick={() => history.push("/coordinator")}
							style={{ fontSize: 16 }}
						>
							Coordinator
						</Menu.Item>
						<Menu.Item
							key="5"
							icon={<DashboardOutlined />}
							onClick={() => history.push("/limits")}
							style={{ fontSize: 16 }}
						>
							Limits
						</Menu.Item>
						<Menu.Item
							key="6"
							icon={<UnorderedListOutlined />}
							onClick={() => history.push("/results")}
							style={{ fontSize: 16 }}
						>
							Results
						</Menu.Item>
						<Menu.Item
							key="7"
							icon={<OrderedListOutlined />}
							onClick={() => history.push("/bets")}
							style={{ fontSize: 16 }}
						>
							Bets
						</Menu.Item>
						<Menu.Item
							key="8"
							icon={<FileOutlined />}
							onClick={() => history.push("/reports")}
							style={{ fontSize: 16 }}
						>
							Reports
						</Menu.Item>
						<Menu.Item
							key="9"
							icon={<FileOutlined />}
							onClick={() => history.push("/operation-stats")}
							style={{ fontSize: 16 }}
						>
							Operation stats
						</Menu.Item>
						<Menu.Item
							key="10"
							icon={<SettingOutlined />}
							onClick={() => history.push("/server-configuration")}
							style={{ fontSize: 16 }}
						>
							Server Configuration
						</Menu.Item>
						<Menu.Item
							style={{ fontSize: 16 }}
							key="12"
							icon={<LogoutOutlined />}
							onClick={handleLogout}
						>
							Logout
						</Menu.Item>
					</Menu>
					{/* <div className={css3} style={{ height: 50, margin: 16 }}>
            <img
              alt="avatar"
              src={require("../assets/avatar.png")}
              style={{ height: 30, width: 30 }}
            />
            <p className={css2}>Admin</p>
          </div> */}
				</Sider>
				<Layout className="site-layout">
					<div className="h-12 flex flex-row justify-between mb-6 p-3 w-full bg-white">
						<p className="text-gray-500">
							Logged in: {moment().format("MMMM, DD YYYY hh:ss a")}
						</p>
						<div className="flex flex-row">
							<p className="mr-3">Admin</p>
							<Button
								type="text"
								shape="circle"
								size="small"
								onClick={() => history.push("/user-info")}
							>
								<img
									alt="avatar"
									src={require("../assets/avatar.png")}
									style={{ height: 30, width: 30 }}
								/>
							</Button>
						</div>
					</div>
					<Content
						style={{ margin: "0 16px", borderRadius: 25 }}
						className="bg-white"
					>
						{children}
					</Content>
					<Footer style={{ textAlign: "center" }}>Admin @2021</Footer>
				</Layout>
			</Layout>
		</div>
	);
};

export default GuestLayout;
