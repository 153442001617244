import React, { useContext, useMemo, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Col,
  Typography,
  Select,
} from "antd";
import { PageContext } from "../../../lib/context";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_AGENT,
  CREATE_COORDINATOR,
  DELETE_AGENT,
  UPDATE_USER,
} from "../../../lib/mutations";

import { ResetPassword } from "./resetpass";

const { Option } = Select;

export const CreateUser = () => {
  const {
    visible,
    setVisible,
    row,
    closePassModal,
    showPassModal,
    resetPassModal,
    setId,
    setShowForm,
    showForm,
  } = useContext(PageContext);

  const [createAgent] = useMutation(CREATE_COORDINATOR);
  const [updateAgent] = useMutation(UPDATE_USER);
  const [removeAgent] = useMutation(DELETE_AGENT);
  //const [agentType, setAgentType]= useState();

  const { Link } = Typography;
  const [form] = Form.useForm();

  useMemo(() => {
    if (Object.keys(row).length > 0) {
      console.log("row", row);
      form.setFieldsValue({ ...row });
      setVisible({ ...visible, agent: true });
      setId(row.id);
    } else {
      form.resetFields();
    }
  }, [row]);

  const addAgent = async (payload) => {
    delete payload["confirmPassword"];
    // let agent_type = agentType;

     console.log("yea",payload)
    // return;
    const response = await createAgent({
      variables: {
        ...payload,
      },
    });
    if (response?.data?.create_agent?.code == 200) {
      notification.open({
        message: "Successfully created agent",
        type: "success",
      });
      form.resetFields();
      setShowForm(false);
      setVisible({ ...visible, agent: false });
    } else {
      notification.open({
        message: "Failed to create agent",
        type: "error",
      });
    }
  };

  const update = async (payload) => {
    const response = await updateAgent({
      variables: {
        id: row.id,
        ...payload,
      },
    });
    if (response?.data?.update_users?.affected_rows) {
      notification.open({
        message: "Successfully updated agent",
        type: "success",
      });
      form.resetFields();
      setShowForm(false);
    } else {
      notification.open({
        message: "Failed to update agent",
        type: "error",
      });
    }
  };

  const handleFinishForm = async (payload) => {
    // console.log("payload",payload)
    // return;
    if (!Object.keys(row).length) await addAgent(payload);
    else await update(payload);
  };

  const handleDelete = async () => {
    let a = window.confirm("Are you sure you want to delete this agent?");
    if (a == true) {
      try {
        notification.success({
          message: "Deleting Agent please wait.",
        });
        let res = await removeAgent({
          variables: {
            id: row.id,
          },
        });
        if (res?.data?.delete_users?.affected_rows) {
          notification.success({
            message: "Successfully deleted agent",
          });
          setShowForm(false);
        }
      } catch (error) {
        notification.error({
          message: "Failed to delete agent",
          description: error.message,
        });
      }
    }
  };

  return (
    <Modal
      visible={showForm}
      title="Create Coordinator"
      okText="Save"
      okButtonProps={{ style: { display: "none" } }}
      footer={null}
      onCancel={() => setShowForm(false)}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinishForm}>
        <Form.Item
          name="username"
          label="Username"
          required
          rules={[{ required: true }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="firstname"
          label="First name"
          required
        >
          <Input placeholder="First name" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="lastname"
          label="Last name"
          required
        >
          <Input placeholder="Last name" />
        </Form.Item>

        {!Object.keys(row).length && (
          <>
            <Form.Item
              rules={[{ required: true }]}
              name="password"
              label="Password"
              required
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm password"
              required
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm password" />
            </Form.Item>
          </>
        )}
        <Form.Item
          rules={[{ required: true }]}
          name="agent_type"
          label="Type"
          required
        >
          <Select placeholder="Select type">
            <Option value="coordinator">Coordinator</Option>
          </Select>
        </Form.Item>

        <Row>
          <Col span={8}>
            <Button name="submit" htmlType="submit" type="primary">
              Save
            </Button>
          </Col>
          {showForm && (
            <>
              <Col span={8}>
                <Button name="delete" type="primary" onClick={handleDelete}>
                  Delete Agent
                </Button>
              </Col>
              <Col span={8}>
                <Link onClick={showPassModal} type="link">
                  Reset Password?
                </Link>
                <Modal
                  visible={resetPassModal}
                  onCancel={closePassModal}
                  footer={null}
                >
                  <ResetPassword />
                </Modal>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Modal>
  );
};
