import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Button, DatePicker, Form, Input, Spin } from "antd";

const ServerConfigurationView = () => {
  const { handleFinishForm, form, loading } = useContext(PageContext);

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">Server Configuration</p>
      <div className="flex flex-row mt-5">
        {loading ? (
          <Spin />
        ) : (
          <Form form={form} onFinish={handleFinishForm} layout="vertical">
            <div className="flex flex-col">
              <div className="flex flex-row gap-4">
              <Form.Item
                label="Usher Winning multiplier"
                name="winning_multiplier"
                required
                rules={[{ required: true }]}
              >
                <Input placeholder="Winning multiplier" className="w-full" />
              </Form.Item>

              <Form.Item
                label="Usher Winning multiplier same digit"
                name="winning_multiplier_same_digit"
                required
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Winning multiplier same digit"
                  className="w-full"
                />
              </Form.Item>
              </div>
              {/* <div className="flex flex-row">
              <Form.Item
                label="Outlet Winning multiplier"
                name="outlet_winning_multiplier"
                required
                rules={[{ required: true }]}
              >
                <Input placeholder="Outlet winning multiplier" className="w-full" />
              </Form.Item>

              <Form.Item
                label="Outlet winning multiplier same digit"
                name="outlet_winning_multiplier_same_digit"
                required
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Outlet winning multiplier same digit"
                  className="w-full"
                />
              </Form.Item>
              </div> */}
            </div>
            <p className="text-base font-bold">Daily cut off schedule</p>
            <div className="flex flex-row">
              <Form.Item
                label="First cut off start"
                name="first_cut_off_start"
                required
                rules={[{ required: true }]}
                className="mr-5"
              >
                <DatePicker.TimePicker
                  format="HH:mm"
                  use12Hours
                  onChange={(time, timeString) => console.log(time, timeString)}
                />
              </Form.Item>
              <Form.Item
                label="First cut off end"
                name="first_cut_off_end"
                required
                rules={[{ required: true }]}
              >
                <DatePicker.TimePicker format="HH:mm" use12Hours />
              </Form.Item>
            </div>
            <div className="flex flex-row">
              <Form.Item
                label="Second cut off start"
                name="second_cut_off_start"
                required
                rules={[{ required: true }]}
                className="mr-5"
              >
                <DatePicker.TimePicker format="HH:mm" use12Hours />
              </Form.Item>
              <Form.Item
                label="Second cut off end"
                name="second_cut_off_end"
                required
                rules={[{ required: true }]}
              >
                <DatePicker.TimePicker format="HH:mm" use12Hours />
              </Form.Item>
            </div>
            <div className="flex flex-row">
              <Form.Item
                label="Third cut off start"
                name="third_cut_off_start"
                required
                rules={[{ required: true }]}
                className="mr-5"
              >
                <DatePicker.TimePicker format="HH:mm" use12Hours />
              </Form.Item>
              <Form.Item
                label="Third cut off end"
                name="third_cut_off_end"
                required
                rules={[{ required: true }]}
              >
                <DatePicker.TimePicker format="HH:mm" use12Hours />
              </Form.Item>
            </div>

            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
};

export default ServerConfigurationView;
