import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Button, Spin, Descriptions } from "antd";
import { EditUser } from "./components/edituser";
import { ResetPassword } from "./components/resetPassword";

const UsersInfoView = () => {
  const { usersInfo, loading_usersInfo, setVisible } = useContext(
    PageContext
  );

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">Users Info</p>
      <div className="flex flex-row mt-5">
      </div>
      <div className="mt-10 flex flex-row"> 
        <div className="w-screen mr-4">
          <Button
            className="w-20 mb-5" 
            type="primary"
            onClick={() => setVisible(true)}
          >
            Edit User Profile
          </Button>
          <p className="text-xs font-bold">User Profile</p>
          {loading_usersInfo ? (
            <>
              <div className="flex h-full">
                <Spin className="self-center" />
              </div>
            </>
          ) : (
            <>
            <Descriptions layout="vertical" bordered>
              <Descriptions.Item label="Username">{`${usersInfo?.users[0].username}`}</Descriptions.Item>
              <Descriptions.Item label="First Name">{`${usersInfo?.users[0].firstname}`}</Descriptions.Item>
              <Descriptions.Item label="Lastname">{`${usersInfo?.users[0].lastname}`}</Descriptions.Item>
              <Descriptions.Item label="Email">{`${usersInfo?.users[0].email}`}</Descriptions.Item>
              <Descriptions.Item label="Municipality">{`${usersInfo?.users[0].municipality}`}</Descriptions.Item>
              <Descriptions.Item label="Type">{`${usersInfo?.users[0].type}`}</Descriptions.Item>
            </Descriptions>
            </>
          )}
        </div>
      </div>

      <EditUser />
      <ResetPassword />
    </div>
  );
};

export default UsersInfoView;
