import React, { useContext, useEffect } from "react";
import { Button, Form, Input, Modal, notification } from "antd";
import { PageContext } from "../../../lib/context";
import { useMutation } from "@apollo/react-hooks";
import { RESET_PASSWORD } from "../../../lib/mutations";

export const ResetPassword = () => {
  const { usersInfo, showPassForm, setShowPassForm } = useContext(PageContext);

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const [form] = Form.useForm();

  useEffect(() => {
    if(usersInfo){
      form.setFieldsValue({...usersInfo?.users[0]})
    }
  }, [usersInfo])

  const resetUserPassword = async (payload) => {
    delete payload["confirmPassword"];
    const response = await resetPassword({
      variables: {
        user_id: payload.id,
        password: payload.password,
      },
    });
    if (response?.data?.reset_password?.code == 200) {
      notification.open({
        message: "Password Reset Successfully",
        type: "success",
      });
      form.resetFields();
      setShowPassForm(false)
    } else {
      notification.open({
        message: "Failed to reset password",
        type: "error",
      });
    }
  };

  const handleFinishForm = async (payload) => {
    await resetUserPassword(payload);
  };

  const handleCancel = () => {
    setShowPassForm(false)
  }
  return (
    <Modal
      visible={showPassForm}
      title="Reset Password"
      okText="Save"
      okButtonProps={{ style: { display: "none" } }}
      footer={null}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinishForm}>
            <Form.Item
              name="id"
              label="id"
              hidden
            >
              <Input/>
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              name="password"
              label="Password"
              required
            >
              <Input.Password placeholder="Enter New Password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm password"
              required
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm password" />
            </Form.Item>
        <Button htmlType="submit" type="primary">
          Reset
        </Button>
      </Form>
    </Modal>
  );
};
