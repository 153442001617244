import React, { useEffect, useMemo } from "react";
import { useContext, useState } from "react";
import { PageContext } from "../../../lib/context";
import { Spin, Table } from "antd";
import {
  formatMoney,
  calculateWinnersAndWinnings,
  getTotalWinnersAndWinnings,
} from "../../../lib/util";
import { ExportCsvData } from "../exportCsvData";
import { GET_AGENT_BETS } from "../../../lib/query";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

export const Daily = () => {
  const { getColumnSearchProps, filters } = useContext(PageContext);

  const [tableData, setTableData] = useState([]);
  const [totals, setTotals] = useState({
    bets: 0,
    betCount: 0,
    winners: 0,
    winnings: 0,
  });

  const { data: daily_bets, loading: loading_daily_bets } = useQuery(
    GET_AGENT_BETS,
    {
      variables: {
        resultsWhere: {
          _and: [
            { date: { _gt: moment(filters.dateFrom).format("YYYY-MM-DD") } },
            {
              date: {
                _lt: moment(filters.dateTo).add(1, "days").format("YYYY-MM-DD"),
              },
            },
            filters?.draw !== null ? { drawNumber: { _eq: filters.draw } } : {},
          ],
        },
        betsWhere: {
          _and: [
            {
              created_at: {
                _gt: moment(filters.dateFrom).format("YYYY-MM-DD"),
              },
            },
            {
              created_at: {
                _lt: moment(filters.dateTo).add(1, "days").format("YYYY-MM-DD"),
              },
            },
            filters?.draw !== null ? { drawNumber: { _eq: filters.draw } } : {},
          ],
          user:
            filters?.agent?.length > 0 ? { id: { _in: filters.agent } } : {},

          //filters?.agent_type !== null ? { agent_type: { _eq: filters.agent_type } } : {},
        },
        usersWhere: {
          _and: [
            { type: { _neq: "admin" } },   
            { type: { _neq: "coordinator" } }, 
            filters?.agent?.length > 0 ? { id: { _in: filters.agent } } : {},
            filters?.agent_type !== null
              ? { agent_type: { _eq: filters.agent_type } }
              : {},
            filters?.coordinator?.length > 0
              ? { coordinatorId: { _in: filters.coordinator } } : {},
          ],
        },
      },
    }
  );  

  useEffect(() => {
    console.log("yes", daily_bets);
    if (daily_bets) {
      const payload = [];
      let total = 0;
      let count = 0;
      let winners = 0;
      let winnings = 0;
      let remittance = 0;

      let from = moment(filters.dateFrom).format("YYYY-MM-DD");
      let to = moment(filters.dateTo).format("YYYY-MM-DD");

      for (var m = moment(from); m.isSameOrBefore(to); m.add(1, "days")) {
        daily_bets.users.map((user) => {
          const type = user.agent_type;
          const date = m.format("YYYY-MM-DD");
          const results = _.filter(daily_bets?.results, (o) =>
            moment(moment(o.date).format("YYYY-MM-DD")).isSame(date)
          );
          const bets = _.filter(user.bets, (o) =>
            moment(moment(o.created_at).format("YYYY-MM-DD")).isSame(date)
          );
          const calcWinners = calculateWinnersAndWinnings(
            daily_bets?.serverConfigs,
            results,
            bets,
            type
          ).totalWinners;
          const calcWinnings = calculateWinnersAndWinnings(
            daily_bets?.serverConfigs,
            results,
            bets,
            type
          ).totalWinnings;
 
          const data = {
            date,
            username: user.username,
            agent_type: user.agent_type,
            coordinator: user.coordinator === null ? "" : user.coordinator.username,
            sales: calculateWinnersAndWinnings(
              daily_bets?.serverConfigs,
              results,
              bets,
              type
            ).totalSales,
            winners: calcWinners,
            winnings: calcWinnings,
            remittance:
              calculateWinnersAndWinnings(
                daily_bets?.serverConfigs,
                results,
                bets,
                type
              ).totalWinnings > 0
                ? calculateWinnersAndWinnings(
                    daily_bets?.serverConfigs,
                    results,
                    bets,
                    type
                  ).totalSales -
                  calculateWinnersAndWinnings(
                    daily_bets?.serverConfigs,
                    results,
                    bets,
                    type
                  ).totalWinnings
                : 0,
          };
          total = total + parseFloat(data.sales);
          winners = winners + calcWinners;
          winnings = winnings + parseFloat(calcWinnings);
          remittance = remittance + data.remittance;
          count += bets.length;
          payload.push(data);
        });

        if (m.diff(filters.dateTo, "days") === 0) {
          setTableData(payload);
          setTotals({
            bets: total,
            betCount: count,
            winnings,
            winners,
            remittance: remittance,
          });
        }
      }
    }
  }, [daily_bets, filters]);

  const daily_sales = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Agent",
      key: "agent",
      dataIndex: "username",
      ...getColumnSearchProps("username"),
    },
    // {
    //   title: "Agent Type",
    //   key: "agent_type",
    //   dataIndex: "agent_type",
    //   ...getColumnSearchProps("agent_type"),
    // },
    {
      title: "Sales",
      key: "sales",
      dataIndex: "sales",
      sorter: {
        compare: (a, b) => {
          return a.sales - b.sales;
        },
        multiple: 3,
      },
    },
    {
      title: "Winners",
      key: "winners",
      dataIndex: "winners",
      sorter: {
        compare: (a, b) => {
          return a.winners - b.winners;
        },
        multiple: 3,
      },
    },
    {
      title: "Winnings",
      key: "winnings",
      dataIndex: "winnings",
      sorter: {
        compare: (a, b) => {
          return a.winnings - b.winnings;
        },
        multiple: 3,
      },
    },
    {
      title: "Remittance",
      key: "remittance",
      dataIndex: "remittance",
      sorter: {
        compare: (a, b) => {
          return a.remittance - b.remittance;
        },
        multiple: 3,
      },
    },
    {
      title: "Coordinator",
      dataIndex: ["coordinator"], 
      key: "coordinator",
      ...getColumnSearchProps("coordinator"),
    },
  ];

  const daily_sales_total_title = () => {
    return (
      <div className="text-xs font-bold">
        TOTAL SALES: {totals.bets.toFixed(2)}
        <br /> TOTAL BET COUNT: {totals.betCount}
      </div>
    );
  };

  return (
    <>
      <div className="mt-10 flex flex-row">
        <div className="w-screen mr-4">
          <ExportCsvData
            title="daily"
            filename="Daily.csv"
            totalSales={totals.bets}
            totalWinnings={totals.winnings}
            totalWinners={totals.winners}
            totalRemittance={totals.remittance}
            data={tableData}
          />
          <p className="text-xs font-bold mb-1">Daily Sales</p>
          {loading_daily_bets ? (
            <div className="flex h-full">
              <Spin className="self-center" />
            </div>
          ) : (
            <Table
              dataSource={tableData}
              columns={daily_sales}
              title={daily_sales_total_title}
            />
          )}
        </div>
      </div>
    </>
  );
};
