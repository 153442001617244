import React, { useContext, useMemo, useEffect, useState } from "react";
import { ResetPassword } from "./resetPassword";
import { Button, Form, Input, Modal, notification } from "antd";
import { PageContext } from "../../../lib/context";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_USER } from "../../../lib/mutations";

export const EditUser = () => {
  const { visible, setVisible, usersInfo, setShowPassForm } = useContext(PageContext);
  
  const [updateUser] = useMutation(UPDATE_USER);

  const [form] = Form.useForm();

  useEffect(() => {
    if(usersInfo){
      form.setFieldsValue({...usersInfo?.users[0]})
    }
  }, [usersInfo])

  const editUser = async (payload) => {
    const response = await updateUser({
      variables: {
        ...payload,
      },
    });
    if (response?.data?.update_users?.affected_rows) {
      notification.open({
        message: "Successfully updated agent",
        type: "success",
      });
      form.resetFields();
      setVisible(false);
    } else {
      notification.open({
        message: "Failed to update agent",
        type: "error",
      });
    }
  };

  const handleFinishForm = async (payload) => {
    await editUser(payload);
  };

  const handleCancel = () => {
    setVisible(false) 
  }

  const openResetPassword = () => {
    setShowPassForm(true)
    setVisible(false)
  }
  return (
    <>
    <Modal
      visible={visible}
      title="Edit User Profile"
      okText="Save"
      okButtonProps={{ style: { display: "none" } }}
      footer={null}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinishForm}>
        <Form.Item
          name="id"
          label="id"
          hidden
        >
          <Input/>
        </Form.Item>
        <Form.Item
          name="username"
          label="Username"
          required
          rules={[{ required: true }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="firstname"
          label="First name"
          required
        >
          <Input placeholder="First name" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="lastname"
          label="Last name"
          required
        >
          <Input placeholder="Last name" />
        </Form.Item>
        <Button type="link" onClick={openResetPassword}>
          Reset Password?
        </Button>
        <Button htmlType="submit" type="primary">
          Save
        </Button>
      </Form>
    </Modal>
    <div><ResetPassword/></div>
    </>
  );
};
