import React, { useContext, useEffect, useMemo, useState } from "react"; 
import { Button, Form, Input, Modal, notification, Select, Space } from "antd";
import { PageContext } from "../../../lib/context";
import { useMutation} from "@apollo/react-hooks";
import {  UPDATE_AGENT_COORDINATOR  } from "../../../lib/mutations"; 

const { Option } = Select;

export const CreateBulkUser = () => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [customOption, setCustomOption] = useState("");
  
  const {row, visible, setVisible, agentsList} = useContext(PageContext);  
  const [updateAgentsCoordinator] = useMutation(UPDATE_AGENT_COORDINATOR);

  useEffect(()=> {    
   if(row?.id !== undefined) {
      const initFilteredAgents = agentsList?.users
      ?.filter((user) =>
        user.coordinatorId ===row?.id
      ).map((user) => user.id); 
      setFilteredOptions(initFilteredAgents)
    } 
  }, [visible.bulkagent, row, agentsList])

    const onCustomOption = (e) => { 
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
  
      const newTimeout = setTimeout(() => {
        setCustomOption(e);
      }, 500);
  
      setDebounceTimeout(newTimeout);
    };

    const onSelectAgents = (value) => {
      setFilteredOptions(value); 
    };

    const onSubmitPrefix = async () => {
      let filtered = await agentsList?.users
        ?.filter((user) =>
          user.username.toLowerCase().includes(customOption.toLowerCase())
        )
        .map((user) => user.id);
  
      if (customOption === "") {
        filtered = [];
      }
   
      const data = [
        ...filteredOptions,
        ...filtered
      ] 
      setFilteredOptions(data);  
    };
  
    const onSubmit =async ()=> { 
      const response = await updateAgentsCoordinator({
        variables: {
          agentIds: filteredOptions, 
          coordinatorId: row?.id
        },
      });

      const { updateCoordinatorId, removeCoordinatorId } = response?.data || {};

      if ( updateCoordinatorId?.affected_rows > 0 || 
        removeCoordinatorId?.affected_rows > 0) {
        notification.open({
          message: "Successfully updated agent",
          type: "success",
        });
        setVisible({ ...visible, bulkagent: false })
      } else {
        notification.open({
          message: "Failed to update agent",
          type: "error",
        });
      }
    }

  return (
    <Modal
      visible={visible.bulkagent}
      title="Add Agents"
      okText="Save"
      okButtonProps={{ style: { display: "none" } }}
      footer={null}
      onCancel={() => setVisible({ ...visible, bulkagent: false })}
      cancelButtonProps={{ style: { display: "none" } }}
      className="flex flex-col"
    > 
    <div className="flex flex-col gap-4">
      <Space.Compact className="mr-5 w-56 border  flex-row items-center">
        <div className="border-r">
          <Input
            style={{
              boxShadow: "none",
            }}
            className="w-[90%] border-none"
            placeholder="Custom Prefix"
            onChange={(e) => onCustomOption(e.target.value)}
          />
        </div>

        <Button className="border-none " onClick={onSubmitPrefix}>
          submit
        </Button>
      </Space.Compact>

      <Select
          mode="multiple"
          maxTagCount="responsive"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          value={filteredOptions}
          placeholder="Agent User"
          onChange={onSelectAgents}
          className="mr-5 w-full"
          allowClear
        >
          {agentsList?.users?.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.username}
            </Select.Option>
          ))}
        </Select>

      <Button onClick={onSubmit}  type="primary" className="w-1/4">
        Save
      </Button> 
    </div>
     
    </Modal>
  );
};
